@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  height: 100%;

}
body {
  margin: 0;
  padding: 32px 0;
  height: inherit;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Gilroy", sans-serif, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

#root{
  height: inherit;
}


input[role="combobox"]:focus{
  box-shadow: none !important;
}


.font-family{
  font-family: "Gilroy", sans-serif, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}


@font-face {
  font-family: 'Circularstd';
  src: url('./fonts/CircularStd/CircularStd-Light.woff2');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Circularstd';
  src: url('./fonts/CircularStd/CircularStd-Light\ Italic.woff2');
  font-style: italic;
  font-weight: 100;
}

/* Medium */
@font-face {
  font-family: 'Circularstd';
  src: url('./fonts/CircularStd/CircularStd-Medium.woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Circularstd';
  src: url('./fonts/CircularStd/CircularStd-MediumItalic.woff2');
  font-style: italic;
  font-weight: 400;
}

/* Bold */
@font-face {
  font-family: 'Circularstd';
  src: url('./fonts/CircularStd/CircularStd-Bold.woff2');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Circularstd';
  src: url('./fonts/CircularStd/CircularStd-BoldItalic.woff2');
  font-style: italic;
  font-weight: 700;
}

/* Black */
@font-face {
  font-family: 'Circularstd';
  src: url('./fonts/CircularStd/CircularStd-Black.woff2');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Circularstd';
  src: url('./fonts/CircularStd/CircularStd-BlackItalic.woff2');
  font-style: italic;
  font-weight: 900;
}

/* ------------------------- CircularStd Book (Font) ------------------------ */

@font-face {
  font-family: 'Circularstd book';
  src: url('./fonts/CircularStd/CircularStd-Book.woff2');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Circularstd book';
  src: url('./fonts/CircularStd/CircularStd-BookItalic.woff2');
  font-style: italic;
  font-weight: 700;
}

/* ------------------------- Gilroy Font ------------------------ */

/* font weight : 100 && Normal Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-Thin.ttf');
  font-style: normal;
  font-weight: 100;
}

/* font weight : 100 && Italic Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-ThinItalic.ttf');
  font-style: italic;
  font-weight: 100;
}

/* font weight : 200 && Normal Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-UltraLight.ttf');
  font-style: normal;
  font-weight: 200;
}

/* font weight : 200 && Italic Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-UltraLightItalic.ttf');
  font-style: italic;
  font-weight: 200;
}

/* font weight : 300 && Normal Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-Light.ttf');
  font-style: normal;
  font-weight: 300;
}

/* font weight : 300 && Italic Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-LightItalic.ttf');
  font-style: italic;
  font-weight: 300;
}


/* font weight : 400 && Normal Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}

/* font weight : 400 && Italic Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-RegularItalic.ttf');
  font-style: italic;
  font-weight: 400;
}

/* font weight : 500 && Normal Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}

/* font weight : 500 && Italic Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-MediumItalic.ttf');
  font-style: italic;
  font-weight: 500;
}

/* font weight : 600 && Normal Font Style*/
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
}

/* font weight : 600 && Italic Font Style*/
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-SemiBoldItalic.ttf');
  font-style: italic;
  font-weight: 600;
}
/* font weight : 700 && Normal Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}

/* font weight : 700 && Italic Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-BoldItalic.ttf');
  font-style: italic;
  font-weight: 700;
}

/* font weight : 800 && Normal Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-ExtraBold.ttf');
  font-style: normal;
  font-weight: 800;
}

/* font weight : 800 && Italic Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-ExtraBoldItalic.ttf');
  font-style: italic;
  font-weight: 800;
}

/* font weight : 900 && Normal Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-Heavy.ttf');
  font-style: normal;
  font-weight: 900;
}

/* font weight : 900 && Italic Font Style */
@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy/Gilroy-HeavyItalic.ttf');
  font-style: italic;
  font-weight: 900;
}